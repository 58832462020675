import type { JSONData } from '@/components/JsonKit/JsonEditor';
import type { RpmEnrolledAccounts } from '@/graphql/remotePatientMonitoring';
import type { RpmMonitoringActivityTypes } from '@/routes/PatientDetails/RemotePatientMonitoring/RpmWorkflow';
import type { PatientsData } from '@/routes/Patients/patients';
import type { TruentityDateInput } from '@/types/date';
import type { RelyingParty, RelyingPartyAdminType, RelyingPartyType } from '@/types/graphql';
import type { GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import type React from 'react';
import type { Role } from './admin';
import type { AccountType } from './graphql';

export const APPLE_NAME = 'apple';

export type HealthTypeTab = {
  a11yLabel: string;
  label: string;
  path: string;
  disabled: boolean;
  // eslint-disable-next-line no-undef
  icon: JSX.Element;
};

export type RpmStatusTypeTab = {
  status: string;
  label: string;
  path: string;
  // eslint-disable-next-line no-undef
  icon: JSX.Element;
};

export type TabPanelDataType = {
  index: number;
  label: string;
};

export type AccountDevicesType = {
  id: string;
  name: string;
  brandName: string;
  typeName: string;
  recordedAt?: string;
  createdAt?: string;
};

export type ReadingType = {
  value: string;
  unit: string;
  key: string;
  recordedAt: string;
  createdAt: string;
};

export type AccountsMonitoringDeviceType = {
  id: string;
  externalPatientId: string;
  hardwareId: string;
  hardwarePatientId: string;
  status: string;
  gatewayId: string;
  formattedGatewayId: string;
  signalStrength: number;
  signalStrengthPercentage: number;
  lastGatewayCheckingTime: string;
  shippedAt: string;
  monitoringDevice: AccountDevicesType;
  latestReadings?: ReadingType[];
};

export type TableReadingsType = {
  id: string;
  source: string;
  value: string;
  status: string;
  recordedAt: string;
  isBaseline: boolean;
};

export enum RpmAlertsLabelTypes {
  CRITICALLY_HIGH = 'CRITICALLY_HIGH',
  HIGH = 'HIGH',
  LOW = 'LOW',
  CRITICALLY_LOW = 'CRITICALLY_LOW',
  RPM_SETUP = 'RPM_SETUP',
  NO_READINGS = 'NO_READINGS',
  PROVIDER_CONFIG = 'PROVIDER_CONFIG',
  MED_REGIMEN = 'MED_REGIMEN',
  REPORT_FAX_FAILED = 'REPORT_FAX_FAILED',
  PATIENT_VITAL_ACCESS_BLOCKED = 'PATIENT_VITAL_ACCESS_BLOCKED'
}

export enum StatusTypeFilter {
  CRITICALLY_HIGH = 'Critically High',
  HIGH = 'High',
  NORMAL = 'Normal',
  LOW = 'Low',
  CRITICALLY_LOW = 'Critically Low'
}

export enum CareActivityTypes {
  MONTHLY_CARE_MANAGEMENT = 'Monthly Care Management',
  SETUP = 'Setup'
}

export interface StatusColors {
  textColor: string;
  bgColor: string;
}

export enum VitalsHealthTypes {
  HeartRate = 'heartRate',
  BloodPressure = 'bloodPressure',
  BloodGlucose = 'bloodGlucose'
}

export enum UnitType {
  HeartRate = 'BPM',
  BloodPressure = 'SYS/DIA (mmHg)',
  BloodGlucose = 'mg/dL'
}

export enum BloodPressureType {
  Systolic = 'SYS',
  Diastolic = 'DIA'
}

export interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
  otherProps?: any;
}

export type RpmAlertsCount = {
  criticallyHigh: number;
  high: number;
  low: number;
  criticallyLow: number;
  noReadings: number;
  rpmSetup: number;
  providerConfig: number;
  medRegimen: number;
  reportFaxFailed: number;
  patientVitalAccessBlocked: number;
};

export type RPMAccountAlert = {
  id: string;
  account: {
    id: string;
    truentityId: string;
    user: {
      firstName: string;
      lastName: string;
    };
    birthDate: string;
    zipcode: string;
    phone: string;
    rpmEnrolledAt: string;
    rpmStatus: string;
  };
  description: string;
  reading: string;
  shortDescription: string;
  label?: string;
  careActivity: CareActivityType;
  createdAt: string;
  recordedAt?: string;
  lastReadingAt?: string;
  isRead: boolean;
};

export type RpmCandidateStatisticsType = {
  title: string;
  value: number;
};

export type ConsentType = {
  rpmParticipation: boolean;
  rpmProgramDescriptionAccepted: boolean;
  rpmShareDate: boolean;
  rpmMonthlyReportSharing: boolean;
  rpmCanCancelAnytime: boolean;
  rpmWillReturnDevicesOnCancellation: boolean;
};

export enum RpmStatusTypes {
  IS_CANDIDATE = 'IS_CANDIDATE',
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  SCHEDULE_FOR_REVIEW = 'SCHEDULE_FOR_REVIEW',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ENROLLED = 'ENROLLED',
  SKIPPED = 'SKIPPED',
  UNENROLLED = 'UNENROLLED',
  SCHEDULED_FOR_ENROLLMENT = 'SCHEDULED_FOR_ENROLLMENT'
}

export enum RpmReportReviewTypes {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED'
}

export enum RpmApprovalStatusTypes {
  PENDING = 'PENDING',
  SCHEDULE_FOR_REVIEW = 'SCHEDULE_FOR_REVIEW',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  SKIPPED = 'SKIPPED'
}

export enum ConfirmDialogLabelTypes {
  REJECT = 'REJECT',
  APPROVE = 'APPROVE',
  SEND = 'SEND',
  SKIP = 'INCOMPLETE',
  SEND_FOR_REVIEW = 'SEND FOR REVIEW',
  RESEND_FOR_REVIEW = 'RESEND FOR REVIEW',
  SEND_SCHEDULE_FOR_REVIEW = 'SCHEDULE FOR REVIEW'
}
export const mappedDialogLabelToRpmStatus = {
  [ConfirmDialogLabelTypes.REJECT]: RpmApprovalStatusTypes.REJECTED,
  [ConfirmDialogLabelTypes.APPROVE]: RpmApprovalStatusTypes.APPROVED,
  [ConfirmDialogLabelTypes.SKIP]: RpmStatusTypes.SKIPPED
};

export enum RpmDeviceTypes {
  BPM = 'BPM',
  GLUCOMETER = 'GLUCOMETER'
}

export type RpmStatusCounts = {
  [key in RpmStatusTypes]?: number;
};

export type ContactType = {
  type: string;
  value: string;
  status?: string;
};

export type ProviderType = {
  id: string;
  individualFirstName: string;
  individualLastName: string;
  individualMiddleName?: string;
  name?: string;
  npiNumber: string;
  title?: string;
  email?: string;
  phone?: string;
  fax?: string;
  hasValidSession?: boolean;
  deaNumber?: string;
  taxonomyCode?: string;
  stateLicense?: string;
  contacts: ContactType[];
};

export enum ProviderSessionStatusTypes {
  SUCCESS = 'Success',
  FAILED = 'Fail'
}

/*
 * CONSTANTS USED FOR THE
 * DROPDOWNS
 */
export const BRAND_NAMES = [{ label: 'TENOVI', value: 'Tenovi' }];

export const DEVICE_TYPE_NAMES = [
  { label: RpmDeviceTypes.BPM, value: RpmDeviceTypes.BPM.toLowerCase() },
  { label: RpmDeviceTypes.GLUCOMETER, value: RpmDeviceTypes.GLUCOMETER.toLowerCase() }
];

export enum StatusType {
  Initial = 'Initial',
  Connecting = 'Connecting',
  Connected = 'Connected',
  Removed = 'Removed'
}

export type CheckboxStateType = {
  checked: boolean;
  disabled: boolean;
};

export enum ConfigOptionTypes {
  Provider = 'Provider',
  Patient = 'Patient'
}

export enum ActivityLogType {
  COMMUNICATION_SUMMARY = 'communication-summary',
  READINGS_REVIEW_SUMMARY = 'readings-review-summary',
  REPORTS_SUMMARY = 'reports-summary',
  REPORT_COMMUNICATION_SUMMARY = 'report-communication-summary'
}

export type RefactorActivityLogType = {
  id: string;
  logText: string;
  activityTime?: string | Date;
  activityType?: string;
  children?: { id: string; label: string; labelDesc?: number }[];
};

export type ActivityLog = {
  id: string;
  logText: string;
  account: AccountType;
  activityType: string;
  activityTime: string;
  encounterId: number;
  careActivityId: null | string;
  activityEndTime: null | string;
  timeTakenSecs: null | number;
  createdAt: string;
};

export interface MonthYearType {
  year: string;
  months: string[];
}

export interface AccountNoteType {
  id: string;
  title: string;
  modeOfCapture: string;
  note: string;
  shareWithProvider: boolean;
  pinToProfile: boolean;
  isArchived: boolean;
  createdAt: string;
}

export type medicationType = {
  title: string;
  id: string;
  note: string;
};

export interface CareActivityType {
  id: string;
  title: string;
  notes: string | null;
  category: string;
  subType: string;
  isEncounter: boolean;
  totalTimeSpentSecs: number;
  createdAt: string;
  startedAt: string;
  endedAt: string | null;
}

export interface VitalsDataAvailabilityType {
  isBloodGlucoseDataAvailable: boolean;
  isBloodPressureDataAvailable: boolean;
  isHeartRateDataAvailable: boolean;
}

export interface RpmReportsType {
  id: string;
  status: string;
  monthYear?: string;
  reportStartAt: string;
  reportEndAt: string;
  reportedDocS3Key: string;
  lastReportedOn: string;
  dailySendStatus?: string;
  statusUpdatedAt?: string;
  rpmProviderActivities?: RpmProviderActivitiesType[];
  reviewProviders?: ReviewProviderType[];
  account: AccountType;
}

export interface RpmProviderActivitiesType {
  id: string;
  status?: string;
  performedAt: string | null;
  createdAt: string;
  requestedAt?: string;
  approvalRequestedAt?: string;
  rpmStatusUpdatedAt?: string;
  rpmReport?: RpmReportsType;
  account?: AccountType;
}

export type ParsedDeviceType = Pick<AccountsMonitoringDeviceType, 'id' | 'latestReadings' | 'monitoringDevice'>;

export type RpmAccountsType = Pick<
  PatientsData,
  | 'id'
  | 'truentityId'
  | 'user'
  | 'rpmEnrolledAt'
  | 'rpmUnenrolledAt'
  | 'onboardedAt'
  | 'rpmApprovalStatus'
  | 'rpmSignOffStatus'
  | 'healthPlan'
  | 'setting'
  | 'lastContacted'
  | 'rpmStatus'
> &
  Partial<Pick<PatientsData, 'birthDate' | 'zipcode' | 'phone' | 'doNotCall' | 'accountsAssignments' | 'accountsFollowupReminders'>>;

export type RpmAccountSnapshotType = RpmAccountsType &
  Partial<Pick<RpmEnrolledAccounts, 'isRpmReportAvailable' | 'monthlyTimeSpent' | 'monthlyTotalReadings'>> &
  Partial<{
    devices: ParsedDeviceType[];
  }>;

export const ReportCommunicationActivityLogTypes = {
  RPM_MONTHLY_REPORT_SENT: 'Rpm::MonthlyReport::Sent',
  RPM_MONTHLY_REPORT_NOT_SENT: 'Rpm::MonthlyReport::NotSent',
  RPM_REPORT_SEND_NOW: 'Rpm::MonthlyReport::SendNow',
  UPDATE_REPORT_DAILY_SEND_STATUS_TO_SCHEDULE: 'Rpm::ReportDailySendStatus::ScheduleForSending',
  UPDATE_REPORT_DAILY_SEND_STATUS_TO_SENT: 'Rpm::ReportDailySendStatus::Sent',
  UPDATE_REPORT_DAILY_SEND_STATUS_TO_READY_FOR_SENDING: 'Rpm::ReportDailySendStatus::ReadyToSend',
  RPM_REPORT_FAX_SENT_TO_QUEUE: 'Rpm::Report::FaxSentToQueue',
  RPM_REPORT_FAX_SENT: 'Rpm::Report::FaxSent',
  RPM_REPORT_FAX_NOT_SENT: 'Rpm::Report::FaxNotSent'
};

export const ReportActivityLogTypes = {
  RPM_REPORT_FINALIZED: 'Rpm::MonthlyReport::Finalized',
  RPM_REPORT_NOT_FINALIZED: 'Rpm::MonthlyReport::NotFinalized',
  RPM_REPORT_GENERATED: 'Rpm::MonthlyReport::Generated',
  RPM_REPORT_NOT_GENERATED: 'Rpm::MonthlyReport::NotGenerated',
  RPM_REPORT_REGENERATED: 'Rpm::MonthlyReport::ReGenerated',
  RPM_REPORT_NOT_REGENERATED: 'Rpm::MonthlyReport::NotReGenerated'
};

export enum MedConsultStatusType {
  IN_PROGRESS = 'IN_PROGRESS',
  ENDED = 'ENDED',
  ARCHIVED = 'ARCHIVED'
}

export interface Diagnosis {
  id: string;
  code: string;
  name: string;
}

export enum GLOBAL_LOOKUP_TYPES {
  UNAVAILABLE = 'UNAVAILABLE',
  ENROLL = 'ENROLL',
  REDIRECT = 'REDIRECT'
}
export enum DiagnosisCategories {
  DIABETES = 'DIABETES',
  HYPERTENSION = 'HYPERTENSION',
  OTHER = 'OTHER'
}

export enum DiagnosisSystems {
  ICD = 'icd',
  SNOMED = 'snomed',
  OTHER = 'Other'
}

export type AlertLimitsType = {
  bloodGlucose: AlertLimitType;
  heartRate: AlertLimitType;
  sysBloodPressure: AlertLimitType;
  diaBloodPressure: AlertLimitType;
};

export interface AlertLimitType {
  cLowValue: number | string;
  lowValue: number | string;
  highValue: number | string;
  cHighValue: number | string;
}
export enum RpmWorkflowTab {
  CLINICAL_SUMMARY = 'CLINICAL_SUMMARY',
  DEVICES = 'DEVICES',
  CONSENT_MANAGEMENT = 'CONSENT_MANAGEMENT',
  MEDICAL_RECONCILIATION = 'MEDICAL_RECONCILIATION',
  MEDICAL_CONSULTS = 'MEDICAL_CONSULTS',
  CARE_PLAN = 'CARE_PLAN',
  CONFIGURATION = 'CONFIGURATION'
}

export const RpmWorkflowTabNames: { [key in RpmWorkflowTab]: string } = {
  [RpmWorkflowTab.CLINICAL_SUMMARY]: 'Medical History',
  [RpmWorkflowTab.DEVICES]: 'Devices',
  [RpmWorkflowTab.CONSENT_MANAGEMENT]: 'Consent Management',
  [RpmWorkflowTab.MEDICAL_RECONCILIATION]: 'Medication Reconciliation',
  [RpmWorkflowTab.MEDICAL_CONSULTS]: 'Intake (HPI)',
  [RpmWorkflowTab.CARE_PLAN]: 'Assessment & Plan',
  [RpmWorkflowTab.CONFIGURATION]: 'Configuration'
};

export enum RpmSetupStatusTypes {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS'
}

export type RpmClaimsType = {
  account: AccountType;
  ehrId: string;
  description: string;
  diagnosesCodes: string;
  diagnosesNames: string;
  cptCode: string;
  dateOfService?: TruentityDateInput;
  relyingParty: RelyingPartyType;
  provider?: ProviderType;
};

export enum ClaimsCptCodes {
  RPM_SETUP_CODE = '99453',
  RPM_MONTHLY_MONITORING_CODE = '99457',
  RPM_MONTHLY_MONITORING_ADDITIONAL_CODE = '99458',
  RPM_DAILY_MONITORING_CODE = '99454'
}

export enum ProviderSessionStatus {
  STARTED = 'started',
  INVALID_OTP = 'invalid_otp',
  INVALID_ACCOUNT = 'invalid_account'
}

export type ClinicalRangesFormValues = {
  submitType?: GlobalAlertLimitsSubmitEnum;
  heartRateLimits: [number, number, number, number];
  bloodGlucoseLimits: [number, number, number, number];
  sysBloodPressureLimits: [number, number, number, number];
  diaBloodPressureLimits: [number, number, number, number];
};

export enum RpmConfigurationsParams {
  CLINICAL_RANGES = 'clinical_ranges'
}

export enum GlobalAlertLimitsSubmitEnum {
  SAVE = 'SAVE',
  SAVE_AND_APPLY_TO_ALL = 'SAVE_AND_APPLY_TO_ALL'
}

export enum ProviderReviewTypeEnum {
  REPORT = 'REPORT',
  ACCOUNT = 'ACCOUNT'
}

export enum ReviewSubmitTypeEnum {
  SCHEDULE_FOR_REVIEW = 'SCHEDULE_FOR_REVIEW',
  SEND_NOW = 'SEND_NOW',
  READY_TO_SEND = 'READY_TO_SEND'
}

export enum RpmReportStatusTypeEnum {
  READY_FOR_SENDING = 'READY_FOR_SENDING',
  SCHEDULED_FOR_SENDING = 'SCHEDULED_FOR_SENDING',
  SENT = 'SENT',
  FAILED = 'FAILED'
}

export enum UpdateRpmReportStatusTypeEnum {
  REMOVE = 'REMOVE',
  READY_FOR_SENDING = 'READY_FOR_SENDING',
  SCHEDULED_FOR_SENDING = 'SCHEDULED_FOR_SENDING'
}

export type RpmReportsCommunicationType = {
  rpmReport: RpmReportsType;
  scheduledAt: string;
  scheduledMode: string;
};

export type ReviewProviderType = {
  provider: ProviderType;
  reportSendingContacts: ContactType[];
};

export enum ContactedType {
  PHONE = 'phone',
  MESSAGE = 'message'
}

export enum ContactModeType {
  EMAIL = 'Email',
  TELEPHONE = 'Telephone',
  FAX = 'Fax'
}

export type RpmAlertsTabType = {
  label: string;
  path: string;
  value: RpmAlertTabEnum;
  columnVisibility: GridColumnVisibilityModel;
  defaultAlertTypes: { [key: string]: boolean };
};

export enum RpmAlertTabEnum {
  PATIENTS = 'PATIENTS',
  REPORTS = 'REPORTS'
}

export type RpmPharmacyPerformanceReportType = {
  relyingParty: RelyingPartyType;
  account: AccountType;
  monthlyReadingsCount: number;
  monthlyTimeSpentFormat: string;
  monthlyTimeSpentInMinutes: number;
  baselineReadings: ReadingType[];
  averageReadings: ReadingType[];
};

export type RpmAccountHealthTrackingType = {
  id: string;
  value: string;
  unit: string;
  startedAt: string;
  endedAt?: string;
  key: string;
  sourceType: string;
};

export type RpmFollowupConsultType = {
  id: string;
  consultAt: string;
  signOffAt?: string;
  reviewProvider?: ProviderType;
  accountsFollowupReminder: {
    id: string;
    followUpOn: string;
  };
  reportS3Key?: string;
  status: string;
  statusUpdatedAt?: string;
  isTelehealthVisitLaunched: boolean;
  createdAt: string;
  updatedAt: string;
};

export enum RpmHealthTrackingKeys {
  HEMOGLOBIN_A1C = 'HEMOGLOBIN_A1C'
}

export type RpmAccountSummaryType = {
  carePlanS3Key?: string;
  rpmEncounterS3Key?: string;
  signOffAt?: string;
  isCarePlanFilled?: boolean;
};

export enum RpmAccountSmartGoalStatuses {
  PENDING = 'PENDING',
  MET = 'MET',
  PARTIALLY_MET = 'PARTIALLY_MET',
  NOT_MET = 'NOT_MET'
}

export type SmartGoalCategory = {
  category: string;
  subCategory: {
    categoryName: string;
    templates: SmartGoalTemplateType[];
  }[];
};

export type SmartGoalTemplateType = {
  id: string;
  templateName: string;
  templateText: string;
  category: string;
  subCategory: string;
  placeholders: string[];
  validationRules: JSONData;
};

export type AccountSmartGoalType = {
  account?: AccountType;
  relyingParty?: RelyingParty;
  relyingPartyAdmin?: RelyingPartyAdminType;
  goalContent: string;
  smartGoalTemplate?: SmartGoalTemplateType;
  status: string;
  statusChangeAt: string;
  userInput?: JSONData;
  isArchived: boolean;
  rpmFollowupConsult?: {
    id: string;
  };
  createdAt: string;
  updatedAt: string;
};

export enum AccountRpmMonitoredCondition {
  DIABETES = 'Diabetes',
  HYPERTENSION = 'Hypertension',
  DIABETES_AND_HYPERTENSION = 'Diabetes and Hypertension',
  UNASSIGNED = 'Unassigned'
}

export enum RpmSignOffStatus {
  TELEHEALTH_INITIAL_VISIT_APPROVED = 'TELEHEALTH_INITIAL_VISIT_APPROVED',
  TELEHEALTH_FOLLOWUP_VISIT_APPROVED = 'TELEHEALTH_FOLLOWUP_VISIT_APPROVED',
  ASYNCHRONOUSLY_APPROVED = 'ASYNCHRONOUSLY_APPROVED'
}

export type AccountOrderType = {
  account: AccountType;
  provider: ProviderType;
  orderChecked: boolean;
  orderDatetime: string;
  orderPdf: string;
  orderType: string;
};

export enum AccountOrderTypes {
  RPM_BLOOD_PRESSURE_AND_HEART_RATE = 'RPM_BLOOD_PRESSURE_AND_HEART_RATE',
  RPM_BLOOD_SUGAR = 'RPM_BLOOD_SUGAR'
}

export type ParsedTemplateResult = {
  templateId: string;
  template: SmartGoalTemplateType;
  inputs: {
    key: string;
    value: string;
  }[];
  isValidated?: boolean;
  isCompleted?: boolean;
};

export type CustomGoalType = {
  id: string;
  value: string;
  isCompleted?: boolean;
};

export type TabBasedActiveAdminsType = {
  tabType: RpmWorkflowTab;
  path: string;
  admins: Record<Exclude<Role, Role.ADT_ADMIN>, number>;
};

export type RpmWorkflowTabType = {
  label: string;
  path: string;
  type?: RpmMonitoringActivityTypes;
  tabType: RpmWorkflowTab;
  tabReadOnlyRoleTypes: Role[];
  accessDisableRoleTypes: Role[];
  disableProgress?: boolean;
};

export enum RpmFollowupConsultStatusType {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING'
}

export type RpmFollowupConsultTabType = {
  id: string;
  type: string;
  status: string;
  isManualStatusUpdate: boolean;
  createdAt: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isProgressDisabled?: boolean;
  shouldRefreshTab?: boolean;
};

export enum RpmFollowupConsultFilterType {
  ALL = 'ALL',
  INDIVIDUAL = 'INDIVIDUAL'
}

export type KpiFilterType = {
  filterBy: string;
  truentityIds: string[];
};
