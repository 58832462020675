import type { JSONData } from '@/components/JsonKit/JsonEditor';
import config from '@/config';
import type { Consult } from '@/graphql/med-consult';
import type { RpmEnrolledAccounts, RpmSetupTypes } from '@/graphql/remotePatientMonitoring';
import type { MessageAvailabilityType } from '@/routes/PatientDetails/RemotePatientMonitoring/Reports';
import { color } from '@/styles/assets/colors';
import { theme } from '@/styles/mui-theme';
import type { ActivityLogType } from '@/types/graphql';
import type { MuiColor } from '@/types/mui';
import type {
  AlertLimitsType,
  AlertLimitType,
  CheckboxStateType,
  ClinicalRangesFormValues,
  ContactedType,
  MonthYearType,
  ParsedDeviceType,
  ProviderType,
  RefactorActivityLogType,
  RpmAccountSnapshotType,
  RpmFollowupConsultTabType,
  RpmStatusCounts,
  RpmWorkflowTab,
  StatusColors
} from '@/types/remotePatientMonitoring';
import {
  AccountRpmMonitoredCondition,
  ClaimsCptCodes,
  ConfirmDialogLabelTypes,
  DiagnosisCategories,
  MedConsultStatusType,
  RpmAlertsLabelTypes,
  RpmApprovalStatusTypes,
  RpmReportReviewTypes,
  RpmSetupStatusTypes,
  RpmSignOffStatus,
  RpmStatusTypes,
  StatusType,
  StatusTypeFilter,
  UnitType,
  VitalsHealthTypes
} from '@/types/remotePatientMonitoring';
import { today } from '@/util/date';
import { removeSpaces } from '@/util/string';
import type { Moment } from 'moment';
import moment from 'moment';
import type React from 'react';
import type { UseFormSetValue } from 'react-hook-form';
import { mandatoryTabsForEnrolment, mandatoryTabsForRpmFollowup, months } from './constants';
import ls, { PROVIDER_SESSION_AUTH_KEY } from './localstorage';

export const SELECT_DEFAULT_OPTION: { value: string; label: string } = { value: 'all', label: 'All' };
export const currentDate = new Date();
export const currentYear = today().year();
export const currentMonth = today().month();

export const DEFAULT_DEVICE_LENGTH = 2;

export const PROVIDER_APPROVAL_COMMENT_END_TEXT = 'control and improve their long-term health outcomes. \n';

export const getAlertTypeStyles = (value: string): { labelText: string; labelColor: string } => {
  let labelText = '';
  let labelColor = '';
  if (value === RpmAlertsLabelTypes.CRITICALLY_HIGH) {
    labelColor = color.red900;
    labelText = 'Critically High';
  } else if (value === RpmAlertsLabelTypes.HIGH) {
    labelColor = color.errorMain;
    labelText = 'High';
  } else if (value === RpmAlertsLabelTypes.LOW) {
    labelColor = color.warningDark;
    labelText = 'Low';
  } else if (value === RpmAlertsLabelTypes.CRITICALLY_LOW) {
    labelColor = color.amber800;
    labelText = 'Critically Low';
  } else if (value === RpmAlertsLabelTypes.RPM_SETUP) {
    labelColor = color.truentityBlue[800];
    labelText = 'RPM Setup';
  } else if (value === RpmAlertsLabelTypes.NO_READINGS) {
    labelColor = color.grey500;
    labelText = 'No Readings';
  } else if (value === RpmAlertsLabelTypes.PROVIDER_CONFIG) {
    labelColor = color.truentityBlue[700];
    labelText = 'Provider Config';
  } else if (value === RpmAlertsLabelTypes.MED_REGIMEN) {
    labelColor = color.truentityCyan[300];
    labelText = 'Med Regimen';
  } else if (value === RpmAlertsLabelTypes.REPORT_FAX_FAILED) {
    labelColor = color.warningDark;
    labelText = 'Report Fax Failed';
  } else if (value === RpmAlertsLabelTypes.PATIENT_VITAL_ACCESS_BLOCKED) {
    labelColor = color.orangeDark;
    labelText = 'Vital Access Blocked';
  }
  return { labelText, labelColor };
};

export const getReadingStatusStyles = (label: StatusTypeFilter): StatusColors => {
  const colors: Record<StatusTypeFilter, StatusColors> = {
    [StatusTypeFilter.CRITICALLY_LOW]: {
      textColor: color.white,
      bgColor: color.amber800
    },
    [StatusTypeFilter.LOW]: {
      textColor: color.white,
      bgColor: color.warningDark
    },
    [StatusTypeFilter.NORMAL]: {
      textColor: color.white,
      bgColor: color.green800
    },
    [StatusTypeFilter.HIGH]: {
      textColor: color.white,
      bgColor: color.errorMain
    },
    [StatusTypeFilter.CRITICALLY_HIGH]: {
      textColor: color.white,
      bgColor: color.red900
    }
  };

  return colors[label] || { textColor: 'inherit', bgColor: 'inherit' };
};

export const vitalsTableName = (unitType: UnitType): VitalsHealthTypes => {
  switch (unitType) {
    case UnitType.HeartRate:
      return VitalsHealthTypes.HeartRate;
    case UnitType.BloodPressure:
      return VitalsHealthTypes.BloodPressure;
    case UnitType.BloodGlucose:
      return VitalsHealthTypes.BloodGlucose;
  }
};

export const rpmStatusMapping = {
  0: RpmStatusTypes.SCHEDULE_FOR_REVIEW,
  1: RpmStatusTypes.IN_REVIEW,
  2: RpmStatusTypes.APPROVED,
  3: RpmStatusTypes.REJECTED,
  4: RpmStatusTypes.SKIPPED
};

export const getRpmStatusCount = (tabLabel: string, statusCounts: RpmStatusCounts): number => {
  return statusCounts[tabLabel] || 0;
};

export const getProviderSessionUrl = (sessionId: string) => {
  return `${window.location.origin.toString()}/rpm/${sessionId}`;
};

export const mapRpmStatusToColor = (status: string | undefined): string => {
  switch (status) {
    case RpmStatusTypes.REJECTED:
      return theme.palette.error.main;
    case RpmStatusTypes.IN_REVIEW:
      return theme.palette.grey[400];
    case RpmStatusTypes.SKIPPED:
      return theme.palette.warning.main;
    case RpmStatusTypes.APPROVED:
      return theme.palette.success.main;
    default:
      return theme.palette.grey[300];
  }
};

export const setRpmProviderAuthCode = (authCode: string) => {
  ls.set(PROVIDER_SESSION_AUTH_KEY, authCode);
};

export const getRpmProviderAuthCode = () => {
  return ls.get(PROVIDER_SESSION_AUTH_KEY);
};

export const removeRpmProviderAuthCode = () => {
  ls.remove(PROVIDER_SESSION_AUTH_KEY);
};

export const setRpmProviderReportsIds = (reportIds: string[]) => {
  sessionStorage.setItem('reportsIds', JSON.stringify(reportIds));
};

export const getRpmProviderReportsIds = () => {
  return sessionStorage.getItem('reportsIds') ?? '';
};

export const resetProviderSession = () => {
  removeRpmProviderAuthCode();
  sessionStorage.removeItem('reportsIds');
};

export const mapRpmDialogLabelToStatus = (status: ConfirmDialogLabelTypes): string | undefined => {
  switch (status) {
    case ConfirmDialogLabelTypes.REJECT:
      return RpmStatusTypes.REJECTED;
    case ConfirmDialogLabelTypes.APPROVE:
      return RpmStatusTypes.APPROVED;
    case ConfirmDialogLabelTypes.SKIP:
      return RpmStatusTypes.SKIPPED;
  }
};

export const getApprovalStatusChipStyles = (
  approvalStatus: string,
  rpmSignOffStatus?: string
): {
  color: string;
  text: string;
} => {
  if (approvalStatus === RpmApprovalStatusTypes.APPROVED) {
    const signOffStatusText = mapRpmSignOffStatus({
      rpmSignOffStatus
    });
    return {
      color: color.successDark,
      text: `${signOffStatusText ? `Approved ${signOffStatusText}` : 'Approved'}`
    };
  } else if (approvalStatus === RpmApprovalStatusTypes.REJECTED) {
    return {
      color: color.errorMain,
      text: 'Rejected'
    };
  } else if (approvalStatus === RpmApprovalStatusTypes.IN_REVIEW) {
    return {
      color: color.orangeDarker,
      text: 'In Review'
    };
  } else if (approvalStatus === RpmApprovalStatusTypes.PENDING) {
    return {
      color: color.grey400,
      text: 'Pending'
    };
  } else if (approvalStatus === RpmApprovalStatusTypes.SCHEDULE_FOR_REVIEW) {
    return {
      color: color.warningDark,
      text: 'Scheduled'
    };
  } else if (approvalStatus === RpmApprovalStatusTypes.SKIPPED) {
    return {
      color: color.secondaryDark,
      text: 'Incomplete'
    };
  } else {
    return {
      color: color.primaryDark,
      text: 'Other'
    };
  }
};

export const getColorForTotalReadings = (totalReadings: number): 'success' | 'error' | 'info' => {
  if (totalReadings >= 16) {
    return 'success';
  } else if (totalReadings >= 8) {
    return 'info';
  } else {
    return 'error';
  }
};

export const getColorForMonthlyTimeSpent = (time: number): 'success' | 'error' | 'info' => {
  if (typeof time !== 'number') return 'error';
  if (time < 1200) return 'info';
  return 'success';
};

export const getContentAndTargetTab = (statusType: RpmStatusTypes, confirmButtonText: string) => {
  let content = '';
  let targetTab = '';

  if (statusType === RpmStatusTypes.READY_FOR_REVIEW) {
    content = ConfirmDialogLabelTypes.SEND_SCHEDULE_FOR_REVIEW;
    targetTab = RpmStatusTypes.SCHEDULE_FOR_REVIEW;
  } else if (statusType === RpmStatusTypes.IN_REVIEW) {
    if (confirmButtonText === ConfirmDialogLabelTypes.RESEND_FOR_REVIEW) {
      content = ConfirmDialogLabelTypes.RESEND_FOR_REVIEW;
      targetTab = RpmStatusTypes.IN_REVIEW;
    }
  }

  return { content, targetTab };
};
export const statusColorMapping = new Map<string, MuiColor>([
  [StatusType.Initial, 'info'],
  [StatusType.Connecting, 'warning'],
  [StatusType.Connected, 'success']
]);

export const setCheckboxState = (
  stateSetter: React.Dispatch<React.SetStateAction<CheckboxStateType>>,
  checked: boolean,
  disabled: boolean
) => {
  stateSetter({ checked, disabled });
};

export const refactorActivityLog = (activityLogs: ActivityLogType[]) => {
  const temporarySteps: RefactorActivityLogType[] = [];
  const dateToStepMap = {};
  if (activityLogs) {
    activityLogs.forEach(item => {
      const date = new Date(item.activityTime);
      const dateString = date.toDateString();

      if (!dateToStepMap[dateString]) {
        const step: RefactorActivityLogType = {
          id: temporarySteps.length + 1 + '',
          logText: date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
          children: []
        };
        dateToStepMap[dateString] = step;

        temporarySteps.push(step);
      }
      const step = dateToStepMap[dateString];
      if (item.timeTakenSecs) {
        step.children.push({
          id: item.id,
          logText: item.logText,
          labelDesc: `${item.timeTakenSecs}`
        });
        step.labelDesc += item.timeTakenSecs;
      }
    });
  }

  return temporarySteps;
};

export const generateMonthList = (startYear: number, startMonth: string): MonthYearType[] => {
  const result: MonthYearType[] = [];

  for (let year = currentYear; year >= startYear; year--) {
    const monthYear: MonthYearType = {
      year: year.toString(),
      months: []
    };

    const startMonthIndex = year === currentYear ? currentMonth : 11;

    for (let monthIndex = startMonthIndex; monthIndex >= 0; monthIndex--) {
      if (year === startYear && monthIndex < months.indexOf(startMonth)) {
        break;
      }

      monthYear.months.push(months[monthIndex]);
    }

    result.push(monthYear);
  }

  return result;
};

export const generateMonthListFromDate = (startDate: Date) => {
  const startYear = startDate.getFullYear();
  const startMonth = startDate.toLocaleString('en-US', { month: 'long' });

  return generateMonthList(startYear, startMonth);
};

export const modeOfCaptureNoteList = [
  {
    id: 'TALK WITH PATIENT',
    name: 'Talk with Patient'
  },
  {
    id: 'TEXT WITH PATIENT',
    name: 'Text with Patient'
  },
  {
    id: 'TALK WITH PROVIDER',
    name: 'Talk with Provider'
  },
  {
    id: 'CHART REVIEW',
    name: 'Chart Review'
  },
  {
    id: 'OTHER',
    name: 'Other'
  }
];

export const filterByNoteList = [
  {
    id: 'ALWAYS SHARE',
    name: 'Always Share'
  }
];

export enum RpmAlertsReadStatus {
  ALL = 'all',
  READ = 'read',
  UNREAD = 'unread'
}

export const rpmAlertsReadOptions: Array<{ label: string; value: string }> = [
  {
    label: 'All',
    value: RpmAlertsReadStatus.ALL
  },
  {
    label: 'Read',
    value: RpmAlertsReadStatus.READ
  },
  {
    label: 'Unread',
    value: RpmAlertsReadStatus.UNREAD
  }
];

export const rpmStatusOptions: Array<{ label: string; value: string }> = [
  {
    label: 'Enrolled',
    value: RpmStatusTypes.ENROLLED
  },
  {
    label: 'Unenrolled',
    value: RpmStatusTypes.UNENROLLED
  },
  {
    label: 'Candidate',
    value: RpmStatusTypes.IS_CANDIDATE
  },
  {
    label: 'Schedule for enrollment',
    value: RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT
  }
];

export const activityCategoryList = [
  {
    id: 'Setup',
    name: 'Setup'
  },
  {
    id: 'Monthly Care Management',
    name: 'Monthly Care Management'
  }
];

export const activityTypeList = [
  {
    id: 'RPM',
    name: 'RPM'
  }
];

export const isTimePresent = (time: string | undefined | null): boolean => {
  return time !== undefined && time !== null && time !== '';
};

export const isTimeValid = (time: string | undefined | null): boolean => {
  if (time === undefined || time === null || time === '') {
    return false;
  }
  return true;
};

export function timeStringToSeconds(timeString: string) {
  if (!timeString) {
    return 0;
  }

  const timeArray = (timeString ?? '').split(':')?.map(Number);
  const minutes = timeArray[0] ?? 0;
  const seconds = timeArray[1] ?? 0;
  return minutes * 60 + seconds;
}

export const mapRpmReportReviewStatusToColor = (status: string | undefined): string => {
  switch (status) {
    case RpmReportReviewTypes.PENDING:
    case RpmReportReviewTypes.IN_PROGRESS:
      return theme.palette.grey[400];
    case RpmReportReviewTypes.REJECTED:
      return theme.palette.warning.main;
    case RpmReportReviewTypes.COMPLETED:
      return theme.palette.success.main;
    default:
      return '';
  }
};

export const fetchDocumentUrl = async (reportDownloadUrl: string): Promise<string> => {
  try {
    const response = await fetch(`${reportDownloadUrl}`);
    const blob = await response.blob();
    return URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
  } catch (err) {
    console.error(err);
    return '';
  }
};

export const MedConsultStatusColorMapping = new Map<string, MuiColor>([
  [MedConsultStatusType.IN_PROGRESS, 'warning'],
  [MedConsultStatusType.ARCHIVED, 'info'],
  [MedConsultStatusType.ENDED, 'success']
]);

export function replaceUnderscoresWithSpaces(inputString: string): string {
  return inputString.toLowerCase().replace(/_/g, ' ');
}

export const getAlertLimitsArray = (alertLimits: AlertLimitType): [number, number, number, number] => {
  return [Number(alertLimits.cLowValue), Number(alertLimits.lowValue), Number(alertLimits.highValue), Number(alertLimits.cHighValue)];
};

export const getAlertLimitsObject = (alertLimits: [number, number, number, number]): AlertLimitType => {
  return {
    cLowValue: alertLimits[0]?.toString(),
    lowValue: alertLimits[1]?.toString(),
    highValue: alertLimits[2]?.toString(),
    cHighValue: alertLimits[3]?.toString()
  } as AlertLimitType;
};

export const refactorNotAvailableRpmSetups = (notAvailableRpmSetups: string[]) => {
  return notAvailableRpmSetups.map(notAvailableTab => ({
    key: notAvailableTab,
    isRequiredForEnrollment: mandatoryTabsForEnrolment.includes(notAvailableTab)
  }));
};

export const enrollmentEligibility = (rpmSetupTabs: RpmSetupTypes[], notConsiderForRpmEnrollments?: RpmWorkflowTab[]) => {
  const filteredTabs = rpmSetupTabs.filter(
    setup => !notConsiderForRpmEnrollments || !notConsiderForRpmEnrollments.some(notConsiderTab => notConsiderTab === setup.type)
  );

  const mandatoryTypeArray = filteredTabs.filter(setup => setup.isRequiredForEnrollment);
  return mandatoryTypeArray.every(mandatoryType => mandatoryType.status === RpmSetupStatusTypes.COMPLETED);
};

export const getSelectedTabData = (rpmSetupTabs: RpmSetupTypes[], selectedTabType: RpmWorkflowTab) => {
  return rpmSetupTabs.find(setupTab => setupTab.type === selectedTabType);
};

export const updateRpmTabStatus = async (
  rpmSetupTabs: RpmSetupTypes[],
  tabType: RpmWorkflowTab,
  editRpmStatus: (value: RpmSetupTypes) => void,
  updateRpmSetup: any,
  isOverrideAvailable = true,
  status: RpmSetupStatusTypes = RpmSetupStatusTypes.COMPLETED
) => {
  const selectedTabData = getSelectedTabData(rpmSetupTabs, tabType);
  let isUpdatable = isOverrideAvailable;

  if (!isOverrideAvailable) {
    isUpdatable = !selectedTabData?.isManualStatusUpdate;
  }

  if (selectedTabData?.id && selectedTabData.status !== status && isUpdatable) {
    try {
      const res = await updateRpmSetup({
        variables: {
          rpmSetupId: selectedTabData.id,
          status
        }
      });

      const { rpmSetups, status: responseStatus } = res.data!.updateRpmSetup;
      const variant = responseStatus === 'Success' ? 'success' : 'error';

      if (variant === 'success') {
        editRpmStatus({ ...rpmSetups });
        return true;
      }
    } catch (error) {
      console.error('Failed to update RPM setup:', error);
      return false;
    }
  }
  return false;
};

export const areAllFieldsFilled = (values: Record<string, string | boolean | Moment>) => {
  return Object.values(values).every(value => {
    if (typeof value === 'boolean') {
      return value;
    } else if (typeof value === 'string') {
      return value.trim() !== '';
    } else if (value instanceof moment) {
      return value.isValid();
    }
    return false;
  });
};

export const generateDynamicContent: (messageAvailability: MessageAvailabilityType) => string = jsonObj => {
  const { message } = jsonObj;
  const messages: string[] = [];

  message?.providers.forEach(provider => {
    const methods: string[] = [];
    if (provider?.providerMethodAvailability?.isRpmReportProviderByEmail && provider?.providerContacts?.email) {
      methods.push(`<strong>Email ${provider?.providerContacts?.email}</strong>`);
    }
    if (provider?.providerMethodAvailability?.isRpmReportProviderByText && provider?.providerContacts?.phone) {
      methods.push(`<strong>Text ${provider?.providerContacts?.phone}</strong>`);
    }
    if (provider?.providerMethodAvailability?.isRpmReportProviderByFax && provider?.providerContacts?.fax) {
      methods.push(`<strong>Fax ${provider?.providerContacts?.fax}</strong>`);
    }

    if (methods.length > 0) {
      const providerMessage = `<strong>Provider:</strong> ${provider?.providerName} will receive it via ${methods.join(' and ')}`;
      messages.push(providerMessage);
    }
  });

  let orgProviderMethods: string[] = [];
  message?.orgProviders?.forEach(orgProvider => {
    if (orgProvider?.providerMethodAvailability?.isRpmReportProviderByEmail && orgProvider?.providerContacts?.email) {
      orgProviderMethods.push(`<strong>Email ${orgProvider?.providerContacts?.email}</strong>`);
    }
    if (orgProvider?.providerMethodAvailability?.isRpmReportProviderByText && orgProvider?.providerContacts?.phone) {
      orgProviderMethods.push(`<strong>Text ${orgProvider?.providerContacts?.phone}</strong>`);
    }
    if (orgProvider?.providerMethodAvailability?.isRpmReportProviderByFax && orgProvider?.providerContacts?.fax) {
      orgProviderMethods.push(`<strong>Fax ${orgProvider?.providerContacts?.fax}</strong>`);
    }

    const orgProviderMessage = `<strong>Organization Provider:</strong> ${orgProvider?.providerName} will receive it via ${
      orgProviderMethods?.length > 0 ? [[...orgProviderMethods], '<strong>Portal</strong>'].join(' and ') : '<strong>Portal</strong>'
    }`;
    messages.push(orgProviderMessage);
    orgProviderMethods = [];
  });

  const patientMethods: string[] = [];
  if (message?.patients?.patientMethodAvailability?.isRpmReportPatientByEmail) {
    patientMethods.push(`<strong>Email ${message?.patients?.patientContacts?.email}</strong>`);
  }
  if (message?.patients?.patientMethodAvailability?.isRpmReportPatientByText) {
    patientMethods.push(`<strong>Text ${message?.patients?.patientContacts?.phone}</strong>`);
  }

  if (patientMethods?.length > 0) {
    const patientMessage = `<strong>Patient:</strong> ${message?.patients?.patientName} will receive it via ${patientMethods.join(
      ' and '
    )}`;
    messages.push(patientMessage);
  }

  if (messages.length === 0) {
    return `<ul><li>No configured recipients available</li></ul>`;
  } else {
    return `<ul><li>${messages.join('</li><li>')}</li></ul>`;
  }
};

export const getClaimsChipColor = (cptCode: string): { backgroundColor: string; textColor: string } => {
  let backgroundColor = '';
  let textColor = '';

  switch (cptCode) {
    case ClaimsCptCodes.RPM_MONTHLY_MONITORING_CODE:
      backgroundColor = color.primaryLight;
      textColor = color.primaryMain;
      break;
    case ClaimsCptCodes.RPM_SETUP_CODE:
      backgroundColor = color.secondary100;
      textColor = color.secondaryDark;
      break;
    case ClaimsCptCodes.RPM_MONTHLY_MONITORING_ADDITIONAL_CODE:
      backgroundColor = color.secondaryLight;
      textColor = color.secondaryDark;
      break;
    case ClaimsCptCodes.RPM_DAILY_MONITORING_CODE:
      backgroundColor = color.primary100;
      textColor = color.primaryMain;
      break;
  }

  return { backgroundColor, textColor };
};

export const setAlertLimits = (alertLimits: AlertLimitsType, setValue: UseFormSetValue<ClinicalRangesFormValues>) => {
  if (alertLimits) {
    if (alertLimits.heartRate) {
      setValue('heartRateLimits', getAlertLimitsArray(alertLimits.heartRate));
    }
    if (alertLimits.bloodGlucose) {
      setValue('bloodGlucoseLimits', getAlertLimitsArray(alertLimits.bloodGlucose));
    }
    if (alertLimits.sysBloodPressure) {
      setValue('sysBloodPressureLimits', getAlertLimitsArray(alertLimits.sysBloodPressure));
    }
    if (alertLimits.diaBloodPressure) {
      setValue('diaBloodPressureLimits', getAlertLimitsArray(alertLimits.diaBloodPressure));
    }
  }
};

export const formatErrorMessage = error => {
  if (error instanceof Error) {
    return error.message;
  } else if (typeof error === 'string') {
    return error;
  } else if (error?.message) {
    return error.message;
  }
  return 'An error occurred';
};

export const getProviderFullName = (provider: Partial<ProviderType>) => {
  return `${provider.individualFirstName ?? ''} ${provider.individualLastName ?? ''}`;
};

export const medConsultToString = (consult: Consult) => {
  let result = '';

  consult?.items.forEach(item => {
    result += `Q: ${item.primaryText}\n`;
    result += `A: ${item.responseText || '---'}\n\n`;
  });

  return result;
};

const parseDevice = (device: any): ParsedDeviceType => ({
  id: device['id'],
  latestReadings:
    device['latest_readings']?.map((reading: any) => ({
      value: reading['value'],
      unit: reading['unit'],
      key: reading['key'],
      recordedAt: reading['recorded_at'],
      createdAt: reading['created_at']
    })) || [],
  monitoringDevice: device['monitoring_device']
    ? {
        id: device['monitoring_device']['id'],
        brandName: device['monitoring_device']['brand_name'],
        name: device['monitoring_device']['name'],
        typeName: device['monitoring_device']['type_name']
      }
    : { id: '', brandName: '', name: '', typeName: '' }
});

const getRpmAccountSnapshot = ({
  devices: deviceJsonData,
  account,
  ...otherData
}: RpmEnrolledAccounts): RpmAccountSnapshotType | undefined => {
  try {
    const deviceJson: JSONData = deviceJsonData ? deviceJsonData : {};
    return {
      id: account?.truentityId,
      truentityId: account?.truentityId,
      user: account?.user,
      rpmEnrolledAt: account?.rpmEnrolledAt,
      onboardedAt: account?.onboardedAt,
      rpmApprovalStatus: account?.rpmApprovalStatus,
      rpmSignOffStatus: account?.rpmSignOffStatus,
      setting: account?.setting,
      healthPlan: account.healthPlan,
      lastContacted: {
        type: (otherData.lastContactedType || '') as ContactedType,
        date: otherData.lastContactedDate || ''
      },
      rpmStatus: account?.rpmStatus,
      isRpmReportAvailable: otherData?.isRpmReportAvailable,
      monthlyTimeSpent: otherData.monthlyTimeSpent ?? 0,
      monthlyTotalReadings: otherData.monthlyTotalReadings ?? 0,
      devices: Array.isArray(deviceJson) ? deviceJson.map(parseDevice) : []
    };
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const mapEnrolledAccounts = (rpmEnrolledAccounts: RpmEnrolledAccounts[]): RpmAccountSnapshotType[] => {
  return rpmEnrolledAccounts?.map(getRpmAccountSnapshot).filter((snapshot): snapshot is RpmAccountSnapshotType => snapshot !== undefined);
};

export const extractValueFromJson = (json: string, key: string) => {
  try {
    const jsonSetting = JSON.parse(json);
    const extractedValue = jsonSetting[key] || undefined;
    return extractedValue;
  } catch (error) {
    console.error('Encountered an error while extracting data from JSON:', error);
    return undefined;
  }
};

type MapRpmSignOffStatusProps = {
  rpmSignOffStatus?: string;
};

export const mapRpmSignOffStatus = ({ rpmSignOffStatus }: MapRpmSignOffStatusProps): string | null => {
  if ((rpmSignOffStatus?.length ?? 0) > 0) {
    switch (rpmSignOffStatus as RpmSignOffStatus) {
      case RpmSignOffStatus.ASYNCHRONOUSLY_APPROVED:
        return 'Asynchronous';
      case RpmSignOffStatus.TELEHEALTH_FOLLOWUP_VISIT_APPROVED:
        return 'Telehealth Follow-up Visit';
      case RpmSignOffStatus.TELEHEALTH_INITIAL_VISIT_APPROVED:
        return 'Telehealth Initial Visit';
      default:
        return null;
    }
  } else {
    return null;
  }
};

export const getConditionStrings = (conditions: string[] | undefined) => {
  if (!conditions || conditions.length === 0) return AccountRpmMonitoredCondition.UNASSIGNED;

  const hasBloodGlucose = conditions.includes('Blood Glucose');
  const hasBloodPressure = conditions.includes('Blood Pressure');

  if (hasBloodGlucose && hasBloodPressure) {
    return AccountRpmMonitoredCondition.DIABETES_AND_HYPERTENSION;
  } else if (hasBloodGlucose) {
    return AccountRpmMonitoredCondition.DIABETES;
  } else if (hasBloodPressure) {
    return AccountRpmMonitoredCondition.HYPERTENSION;
  }

  return AccountRpmMonitoredCondition.UNASSIGNED;
};

export const getDiagnosisUsingConditions = (condition: AccountRpmMonitoredCondition) => {
  let conditions: DiagnosisCategories[] = [];

  switch (condition) {
    case AccountRpmMonitoredCondition.DIABETES_AND_HYPERTENSION:
      conditions = [DiagnosisCategories.DIABETES, DiagnosisCategories.HYPERTENSION];
      break;
    case AccountRpmMonitoredCondition.DIABETES:
      conditions = [DiagnosisCategories.DIABETES];
      break;
    case AccountRpmMonitoredCondition.HYPERTENSION:
      conditions = [DiagnosisCategories.HYPERTENSION];
      break;
    default:
      conditions = [];
      break;
  }

  return conditions;
};

export function formatRpmStatusString(text: string) {
  if (text === RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT) return 'Scheduled';

  const words = text.split('_');
  const formattedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  const formattedString = formattedWords.join(' ');

  return formattedString;
}

type RedirectToDoxyUrlParams = {
  firstName?: string;
  lastName?: string;
  relyingPartyName?: string;
  hidePrevModal?: () => void;
};

export const redirectToDoxyUrl = ({ firstName, lastName, relyingPartyName, hidePrevModal }: RedirectToDoxyUrlParams) => {
  const doxyUrl = config.TRUENTITY_DOXY_URL;

  if (doxyUrl) {
    if (hidePrevModal) {
      hidePrevModal();
    }

    const enFirstName = encodeURIComponent(removeSpaces(firstName || ''));
    const enLastName = encodeURIComponent(removeSpaces(lastName || ''));
    const enRelyingPartyName = encodeURIComponent(removeSpaces(relyingPartyName || ''));

    const username = `${enFirstName}-${enLastName}-(${enRelyingPartyName})`;
    const urlWithParams = `${doxyUrl}?username=${username}&autocheckin=true`;

    window.open(urlWithParams, '_blank');
  } else {
    console.error('Doxy URL is not set');
  }
};

export const getFollowupConsultEligibility = (
  rpmFollowupConsultTabs: Pick<
    Record<RpmWorkflowTab, RpmFollowupConsultTabType>,
    RpmWorkflowTab.CLINICAL_SUMMARY | RpmWorkflowTab.MEDICAL_CONSULTS | RpmWorkflowTab.CARE_PLAN | RpmWorkflowTab.MEDICAL_RECONCILIATION
  >
): boolean => {
  return mandatoryTabsForRpmFollowup.every(tab => rpmFollowupConsultTabs[tab]?.status === RpmSetupStatusTypes.COMPLETED);
};
